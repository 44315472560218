import { useState } from "react";
import Modal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBell } from "@fortawesome/free-solid-svg-icons";
import styles from "./Header.module.css";
import logo from "../assets/Logo.png";

const Header = () => {

  return (
    <div className={styles.GraphCustomer}>

    </div>
  );
};

export default Header;
